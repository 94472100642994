@import '../../scss/variables/colors.module.scss';

@media screen and (max-width: 640px) {
  .sider {
   margin-top: -54px;
  } 
}

.sider {
  height: calc(100vh - 65px);
  background: white !important;
  border-right: 1px solid $borderColor;

  div[class*='ant-tabs-nav'] {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 0 0 5px;
    height: 53px;
    &::before {
      border-color: $borderColor;
    }
  }

  .tabsWrap {
    position: relative;
    height: 100%;

    .tabsStyles {
      width: 100%;
    }

    .resizer {
      top: 0;
      right: 0;
      position: absolute;
      height: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 10px;
      width: 10px;
      justify-self: flex-end;
      cursor: col-resize;
      resize: horizontal;

      &:hover {
        width: 5px;
        background: #c1c3c5b4;
      }
    }
  }
}
