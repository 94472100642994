@import '../../scss/variables/colors.module.scss';

.forms {
  &_data {
    width: 600px;

    textarea {
      resize: none;
    }
  }
}

.address {
  display: flex;
  width: 600px;
  flex-wrap: wrap;
  justify-content: space-between;

  &_col {
    width: 50%;
  }

  div[class='ant-space-item'] {
    width: 50%;
  }
}

.personaldetails {
  font-size: 13;
  color: $primary100;
  cursor: pointer;
}

.mb20{
  margin-bottom: 20px;
}
