@import '../../scss/variables/colors.module.scss';

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .mainSpace {
    height: calc(100vh - 150px);
    border-radius: 4px;
    background-color: white;
    margin: 15px;
    border: 1px solid $borderColor;
  }

  .dropDownNone {
    display: none;
  }
  
  .dropDownActive {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    opacity: .6;
    z-index: 1100;
    padding: 40px;
    background-color: grey;

    div[class*="ant-upload-drag-container"] {
      border: 10px dashed rgb(127, 86, 216, .25) !important;
      border-radius: 15px;
    }

    div[class*="ant-upload-drag"] {
      border: 0px;
    }
  }

}

