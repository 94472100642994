.modal {
  div[class='ant-modal-body'] {
    padding: 20px;
  }
}

.form {
  &_item {
    font-weight: 500;
  }
}
