@import '../../scss/variables/colors.module.scss';


.scheduleWrap {
  background-color: white;
  margin: 15px;
  overflow: hidden;
  border: 1px solid $borderColor;
  border-radius: 4px;
  height: calc(100vh - 95px);

  div[class*=ant-tabs-nav] {
    margin: 2px 2px 0 2px !important;
    // width: 99.5%;
  };
}

.scheduleTable {
  height: 100%;
  border-radius: 5px;
  padding: 15px;
  background-color: white;
}
