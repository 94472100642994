@import '../../../scss/variables/colors.module.scss';



.modalWrap {
  width: 100%;
  height: auto;
  padding: 30px 20px 20px 20px;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    // font-weight: 500;
    font-size: 16px;
  }

  .itemsWrap {
    display: flex;
    flex-direction: row;
    
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      min-height: 120px;
      width: 50%;
      margin: 20px;
      padding: 20px;
      border-radius: 5px;
      border: 1px solid $borderColor;
      background-color: $hover200;

      .textItem {
        padding-bottom: 20px;
      }
    }
  }

}
