@import '../../scss/variables/colors.module.scss';

.deleteWrap {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
  }

  .delete {
    color: $pdf;
  }
}

.mainWrap {
  position: relative;
  padding: 15px;
  background-color: white;
  margin-top: 15px;
  border: 1px solid $borderColor;
  border-radius: 4px;

  .tableStyles {
    width: auto;
    width: calc(100% - 1.5px);
    height: calc(100vh - 279px);
    // border: 1px solid $borderColor;
    border-radius: 5px;
  }
  // th[class*=ant-table-cell] {
  //   padding: 16px 8px !important;
  // };
  // td[class*=ant-table-cell] {
  //   padding: 16px 8px !important;
  // };
}

.addButton {
  margin-top: 15px;
}

.loaderWrap {
  margin-top: 15px;
  border: 1px solid $borderColor;
  border-radius: 4px;
  padding: 0 5px;
  background-color: white;
  height: calc(100vh - 198px);
}

.emptyText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 367px);
}

.infoWrap {
  height: calc(100vh - 188px);
  overflow-y: scroll;
  overflow-x: hidden;

  .buttonWrap {
    width: fit-content;
    position: sticky;
    bottom: 0;
    left: 0;

    // padding: 15px 15px 15px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .groupName {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    font-size: 14px;
    color: $textcolor2;

    .groupTitle {
      padding-bottom: 10px;
    }

    .fieldWrap {
      width: 100%;
      text-align: start;
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;

      .fieldTitle {
        width: 40%;
        padding-right: 10px;
      }

      .fieldValue {
        font-weight: 400;
        font-size: 14px;
        width: 60%;
        // word-break: break-all;
        // hyphens: auto;
      }
    }
  }
}
