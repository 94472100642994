@import '../../../../scss/variables/colors.module.scss';

.versionsWrap {
  height: calc(90vh - 54px - 45px);
  border: 1px solid $borderColor;
  border-radius: 8px;

  .versionsTable {
    height: calc(100% - 40px);

    .table {
      border-bottom: 1px solid $borderColor;
    }
  }

  .newVersion {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
}

.textToCenter {
  text-align: center !important;
  color: $textcolor;
}

.authorIcon {
  color: $primary100;
}

.commentDiv {
  max-height: 84px;
  overflow-y: scroll;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
}

.iconButton {
  &:hover {
    color: $primary100 !important;
  }
}

.details {
  display: flex;
  flex-direction: row-reverse;
}

td[class*=ant-table-cell]:has(div.commentDiv,div.details,div.current){
  padding: 4px !important;
}

.hoverable {
  &:hover {
    cursor: pointer;
  }
}

.confirmText {
  padding: 6px 0;
  font-size: 16px;
  font-weight: 500;
  // color: $textcolor;
}

.confirmSubText {
  font-weight: 500;
  padding-top: 14px;
  font-size: 13px;
  color: $textcolor;
}

.confirmDocCard {
  border: 1px solid $borderColor;
  border-radius: 4px;
  padding: 10px;
}

.current {
  border-radius: 3px;
  color: $primary100;
  font-size: 12px;
  font-weight: 500;
  padding: 2px;
  background-color: $hover100;
}
