@import '../../scss/variables/colors.module.scss';

$lg: 54px;

.mainWrap {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image {
  width: 35%;
  height: 100vh;
  background: #c7b39b url('../../assets/img/unsplash_E2i7Hftb_rI.png');
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &_title {
    color: #ffffff !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.resetElem {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;

  .localizationSelect {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

@media screen and (max-width: 960px) {
  .resetCard {
    width: 80%;
  }
}

@media screen and (min-width: 960px) {
  .resetCard {
    width: 30%;
  }
}


.subTitle {
  color: #303468;
}

.resetForm {
  button[type='submit'] {
    width: 100%;
    height: $lg;
    font-size: 20px;
    border-radius: 10px;
  }

  .input {
    font-size: 20px;
    height: $lg;
    padding: {
      left: 20px;
      right: 20px;
    }
    border: 3px solid $primary100;
    border-radius: 10px;

    input {
      padding-left: 10px;
    }
  }

  .input:hover {
    border: 3px solid $primary100;
  }
}

.primary {
  color: $primary100 !important;
  font-weight: 600;
}

.overlay{
  width: fit-content;
  max-width: 500px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  border-radius: 6px;

  &_container{
    padding: 10px;
    position: relative;
  }

  &_close{
    position: absolute;
    top: 0;
    right: 0;
    color: $error;
  }
}
