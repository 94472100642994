@import '../../scss/variables/colors.module.scss';

.invitationsWrap {
  ::-webkit-scrollbar {
    border-radius: 16px;
  }
  ::-webkit-scrollbar-corner {
    color: $verylightgrey;
  }
  ::-webkit-scrollbar-track {
    background-color: $verylightgrey;
    border-radius: 16px;
  }
  ::-webkit-scrollbar-thumb {
    border-color: #d9d9d9;
  }

  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  border: 1px solid $borderColor;
  padding: 8px;
  border-radius: 4px;
  border-color: #d9d9d9;
  width: 100%;
  height: calc(100% - 22px);

  .tagInputStyle {
    width: 280px;
    vertical-align: top;
    border-radius: 5px;
  }
}

.personaldetails {
  font-size: 13;
  color: $primary100;
  cursor: pointer;
}

.titleInvitations {
  padding-bottom: 8px;
}

.settingsWrap {
  background-color: white;
  border: 1px solid $borderColor;
  padding: 8px;
  border-radius: 5px;
  border-color: #d9d9d9;
  width: 100%;
  height: calc(100% - 22px);

  .subWrap {
    height: calc(100% - 32px);
    overflow-y: scroll;
  }

  .columnsSettings {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 12px;
    font-size: 13;
    font-weight: 500;
    // width: 100%;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.glassWaiting {
  color: #faad14;
}

.pendingInvite {
  margin-bottom: 15px;

  div[class='ant-collapse-header'] {
    padding: 5px 7px !important;
  }

  * {
    font-size: 13px;
  }

  &_close {
    color: $red;
    cursor: pointer;
  }

  &_accept {
    color: $success;
  }

  &_decline {
    color: $red;
  }
}

.reinvite {
  transform: rotateY(180deg);
  cursor: pointer;
}
