@import '../../../scss/variables/colors.module.scss';

.wrapUsersSegment {
  background-color: white;
  margin-top: 15px;
  overflow: hidden;
  border: 1px solid $borderColor;
  border-radius: 4px;
  height: calc(100vh - 198px);

  .segmentTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 15px 30px;
    border-bottom: 1px solid $borderColor;
    flex-direction: row;
    height: 56px;
    font-size: 16px;
    font-weight: 600;
    color: black;
    // line-height: 40px;

    .actions {
      display: flex;
      flex-direction: row;

      .count {
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        padding: 0 20px;
      }
    }
  }

  .wrapEmployees {
    height: calc(100vh - 256px);
    overflow-y: scroll;
  }
}

.employeeCard {
  //style={{height: 50, border: '1px solid grey'}}
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 0 0 15px;
  border-bottom: 1px solid $borderColor;

  &:hover {
    background-color: $hover200;
    cursor: pointer;
  }

  .employeeInfo {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;

    .employeeIcon {
      height: 60% !important;
      font-size: 22px;
      color: $primary100;
      padding: 8px;
      margin: 0 10px 0 10px;
      border: 2px solid $primary100;
      border-radius: 50%;
    }

    .employeeData {
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;


      .employeeName {
        font-size: 15px;
        font-weight: 600;
      }

      .employeeEmail {
        padding: 15px;
        font-size: 14px;
      }
    }
  }

  .employeeActions {
    display: flex;
    align-items: center;
    flex-direction: row;

    .roleSelect {
      margin-right: 15px;
    }
  }
  
  .deleteBlock {
    height: 32px;
    width: 32px;
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
      font-size: 18px !important;
      padding-left: 5px;
    }
  }
  
  .deleteButton {
    color: $primary100;
    margin: 0 5px 0 0;
    
    span {
      font-size: 18px !important;
    }

    &:hover {
      span {
        color: $primary100;
      }
    }
  }
}
