@import '../../../scss/variables/colors.module.scss';

.modalDelete {
  .delete {
    padding: 30px 20px;
  }

  .restore {
    padding: 30px 20px 10px 20px;
  }
}
