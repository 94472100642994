@import '../../scss/variables/colors.module.scss';

// NoOrganization
.emptyContainer {
  height: 100%;
  width: 100%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  img {
    max-height: calc(100vh - 250px);
    width: 100%;
    object-fit: cover;
  }

  .text {
    width: 75%;
    padding: 20px 0;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}

// OrganizationTitle
.titleWrap {
  height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: black;

  .titleName {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;

    // .initials {
    //   margin-right: 10px;
    //   color: black;
    //   background-color: #64d2ad;
    // }
  }

  .editIcon {
    margin-top: -10px;
    margin-left: 5px;
    svg {
      font-size: 18px;
      color: $textcolor;

      &:hover {
        color: $primary100;
      }
    }
  }
}

// OrganizationInfo
.segment {
  font-weight: 500;
  margin-top: 10px;

  label[class*="ant-segmented-item-selected"] {
    border: 1px solid $primary100;
  }

  label[class="ant-segmented-item"] {
    border: 1px solid transparent;
  }
}
