@import '../../../scss/variables/colors.module.scss';

@keyframes apear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.elementApear {
  animation: apear .4s ease forwards;
}

.modal {
  overflow: hidden !important;
  
  div[class*=ant-modal-body] {
    padding: 20px 5px 0 20px;
    max-height: calc(90vh - 120px);
    overflow-y: scroll;
  }

  .elementApear {
    opacity: 0;
  }

  .info {
    margin-bottom: 20px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid $borderColor;
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .warningIcon {
      font-size: 96px;
      color: orange;
      padding-bottom: 25px;
    }

    .text {
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      width: 75%;
    }
  }

  .displayNone {
    display: none;
  }
}

.dragger {
  div[class*=ant-upload-drag-container]{
    height: 300px;
  }
}

.modalTitleIcon {
  padding: 0px 3px;
  font-size: 18px;
};

