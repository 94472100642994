@import '../../../scss/variables/colors.module.scss';

.infoModal {
  div[class='ant-modal-body'] {
    overflow-y: scroll;
  }

  .infoWrap {
    max-height: 80vh;
  
    .rowDetails {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 2fr;
      column-gap: 20px;
      row-gap: 20px;
      padding: 15px;
      font-size: 14px;

      &:not(:last-child) {
        border-bottom: 1px solid $borderColor;
      }

      .key {
        padding-left: 25px;
        font-weight: 600;
      }

      .value {
      }
    }
  }
}
