@import '../../../scss/variables/colors.module.scss';

.modal {
  .form_row {
    display: flex;
    gap: 20px;
  }

  &_invite {
    padding: 20px;
    width: 100%;
  }

  &_moreInfo {
    padding: 20px;
    background-color: $hover100;
  }
}

.invatationsWrap {
  padding: 20px;
  width: 100%;
  
  ::-webkit-scrollbar {
    display: none;
  }
  
  .subWrap {
    height: calc(100% - 32px);
    overflow-y: scroll;
  }

  .columnsInvatations {
    display: grid;
    grid-template-columns: 2fr 7fr .25fr;
    gap: 12px;
    font-size: 13;
    font-weight: 500;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .emailValue {
    font-weight: 400;
    text-align: start;
    vertical-align: middle;
    line-height: 32px;
    white-space: nowrap;
  }

  .textValue {
    border: 1px solid $borderColor;
    border-radius: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 15px;
    background-color: white;
    text-align: start;
    vertical-align: middle;
    line-height: 32px;
  }

  .warning {
    background-color: rgba(255, 166, 0, 0.5);
  }

  .copyIcon {
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.none {
  display: none;
}
