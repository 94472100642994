@import "../../scss/variables/colors.module.scss";

.tableArea {
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  };
  
  ::-webkit-scrollbar-track {
    background-color: #fff;
  };
  
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  };

  height: calc(100vh - 260px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  overflow: hidden;
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
};

.textToCenter{
  text-align: center !important;
  color: $textcolor;
};

.rowWrap {
  display: flex;
  overflow: hidden;
  align-items: center;
  word-break: break-all;

  .blockName {
    display: flex;
    flex-direction: column;
  }

  .versionCnt {
    font-size: 12px;
    font-weight: 600;

    &:hover {
      color: $primary100;
      cursor: pointer;
    }
  }
};

tr:has(div.word) {
  // background-color: $verylightgrey !important;
};

thead[class*="ant-table-thead"] th {
  white-space: nowrap !important;
  text-align: flex-start !important;
  // text-transform: uppercase !important;
  font-weight: 500 !important;
  color: $textcolor !important;
};

td[class*="ant-table-cell"] {
  color: $textcolor !important;
};

span[class*="ant-upload-btn"] {
  padding: 0 !important;
};

.view {
  text-align: center;
  font-size: 16px;

  &_icon {
    transition: 0.3s color linear;

    &:hover {
      color: $primary100;
    };
  };

  &_disabled {
    &:hover {
      cursor: not-allowed;
    };
  };
};

.icon{
  position: relative;
  padding-left: 8px;
  margin-right: 5px;
  font-size: 22px;
  height: 36px;
};

.tableRow {
  cursor: pointer;
  user-select: none;
};

.tableRowLoading {
  cursor: progress;
  user-select: none;
  opacity: 0.3;
};

.onDrag{
  transition: 0.3s all linear;
  opacity: 0.5;
};

.onDragLeave{
  opacity: 1;
};

.confidentialIcon {
  position: absolute;
  top: -1.5px;
  left: -0.5px;
  background-color: white;
  height: 19px;
  width: 19px;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 0.5px solid $verylightgrey;

};

td[class*=ant-table-cell-row-hover] {
  .confidentialIcon {
    background-color: $hover100;
  };
};

.scaleHover{
  transition: 0.3s all linear;

  &:hover{
    transform: scale(1.2);
  }
}
