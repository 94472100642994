@import '../../../scss/variables/colors.module.scss';

.body {
  padding: 10px;

  input{
    width: initial;
  }
}

.inputs {
  padding-left: 6px;
  padding-right: 6px;
  box-sizing: content-box;
  border-radius: 4px;
  outline: none;
  border: 1px solid $primary100;
}

.countDown {
  text-align: center;
}
