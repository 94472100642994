.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    // background-color: white;
}

.headerImg {
    width: 100%;
    height: 20vh;
    background: url('../../assets/img/unsplash_E2i7Hftb_rI.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
    background-position: 100% 100%;
}

.title {
    font-weight: 600;
}

.text {
    display: flex;
    width: 100%;
    padding: 0 24px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 24px;
    font-size: 20px;
    color: #000000;
}

.linkWrap {
    display: flex;
    width: 100%;
    padding: 0 32px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.link {
    position: relative;
    margin-top: 48px;
    height: 128px;
    width: 128px;
    cursor: pointer;
}

.apple {
    background: url('../../assets/img/app_store.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 64px;
    &::after {
        content: 'App Store';
        position: absolute;
        bottom: -32px;
        left: 16px;
        font-size: 20px;
        color: #000000;
    }
}

.google {
    background: url('../../assets/img/android-logo-png-transparent.png');
    background-repeat: no-repeat;
    background-size: cover;
    // border: 1px solid #e7e7e7;
    border-radius: 20px;
    &::after {
        content: 'Android .apk';
        position: absolute;
        bottom: -32px;
        left: 4px;
        font-size: 20px;
        color: #000000;
        text-wrap: nowrap;
    }
}
