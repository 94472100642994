@import '../../../scss/variables/colors.module.scss';

.modalMove {
  // .delete {
  //   padding: 30px 20px;
  // }
  max-width: 400px;

  .moveWrap {
    max-height: 80vh;
    overflow: scroll;
    padding: 25px 10px 10px 25px;
  }
}
