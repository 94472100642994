@import '../../../../scss/variables/colors.module.scss';

.addInput {
  display: flex;
  align-items: flex-end;
  padding-top: 20px;

  .input {
    width: calc(100% - 136px)
  }

  .textarea{
    border-radius: 4px;

    textarea::-webkit-scrollbar{
      margin-right: 2px !important;
      border-radius: 10px !important;
    }

    textarea::-webkit-scrollbar-track{
      border-radius: 10px !important;
    }

    textarea::-webkit-scrollbar-thumb{
      border-radius: 10px !important;
    }
  }

  button {
    width: 120px;
  }
}

  
.holder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  height: 200px;
}; 

.notes {
  &_card {
    border-color: $primary100;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  div[class='ant-collapse-content-box'] {
    background-color: $verylightgrey !important;
  }

  div[class='ant-collapse-header'] {
    background-color: $verylightgrey !important;
  }

  &_extra {
    padding-right: 40px;
    color: $primary100;
    cursor: pointer;
  }

  &_title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttons {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
    }
 
    .spinnerWrap {
      height: 50px;
      display: flex; 
      align-items: center;
    }

    .name {
      overflow: hidden;
      text-wrap: wrap;
      text-overflow: ellipsis;
    }
  }

  &_date {
    margin-top: 30px;
  }

  &_paragraph{
    white-space: pre-wrap;
    max-height: 180px;
    overflow-y: auto;
  }
}

.notes_card {
  border-radius: 4px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  div[class*=ant-card-head-title] {
    padding: 0;
  }
  div[class*=ant-card-head] {
    padding: 0 5px;
  }
  div[class*=ant-card-body] {
    padding: 12px;
    div {
      margin: 0;
    }
  }
}

.userLabelWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;

  .labelName {
    font-size: 14px;
    font-weight: 500;
  }
  
  .labelEmail {
    font-size: 12px;
    font-weight: 400;
    color: grey;
  }
}
