@import "../../scss/variables/colors.module.scss";

:root {
  --scale-factor: 1;
}

.PdfPage {
  position: relative;
  display: flex;
  justify-content: center;
}

.PdfPage__textLayer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 1;
  line-height: 1;
}

.PdfPage__textLayer > span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
  font-family:'Times New Roman', Times, serif !important;
}

.page_overlay {
  border-radius: 5px;
  background-color: #ddd;
  border: 1px solid $borderColor;
  outline: "1px solid #ccc";
  width: 100%;
  height: 100%;
}

.WebViewer{
  z-index: 1001;
  user-select: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  transition: 0.3s all linear;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 991;
  left: 0;
  top: 0;
}


.spinner {
  width: 100%;
  z-index: 992;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.error{
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #ccc;
  justify-content: center;
}

.close {
  color: red;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 24px;
  z-index: 990;
}

.download {
  position: absolute;
  right: 10px;
  top: 75px;
  z-index: 990;
}

.xlsxviewer {
  height: 100%;
  background-color: white;

  .ulElem {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    padding-top: 10px;
    padding-left: 40px;
    width: 110vw;
    position: sticky;
    top: 0;
    left: -20px;
    background-color: $hovergrey;
    border-bottom: 1px solid gray;

    .liItem {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border: 1px solid gray;
      border-bottom: none;
      background-color: white;
      margin-right: 5px;
      padding: 10px 15px;
      color: $word;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .xlsxTable {
    height: calc(100% - 50px);
    overflow: scroll;
    padding: 20px 0 0 20px;
  }
}

.videoViewer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
