@import "../../../scss/variables/colors.module.scss";

.confirmWrap {
  padding: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .successIcon {
    font-size: 164px;
    padding-bottom: 24px;
    color: $success;
    // border: 7px solid $success;
    // border-radius: 50%;
    padding: 24px;
  }

  .textConfirm {
    font-size: 20px;
    font-weight: 500;
    // color: $textcolor;
    padding-top: 40px;
    text-align: center;
  }
}
