@import '../../../scss/variables/colors.module.scss';


.modalPermissions  {
  position: relative;
  div[class=ant-modal-header] {
    border-bottom: 0 !important;
  }
}

.modalPermissions div.ant-modal-body {
  background-color: $hovergrey;
}

.modalWrapPermissions {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: $hovergrey;

  .title {
    padding: 10px 10px 0 20px;

    .select {
      max-width: 300px !important;
    }
  }
}
