@import '../../../../scss/variables/colors.module.scss';

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  height: calc(90vh - 102px);

  .viewerWrap {
    height: 100%;
    width: 65%;
    overflow: hidden;
  }

  .sidebar {
    padding: 10px 12px;
    min-width: 300px;
    border-radius: 5px;
    border: 1px solid $borderColor;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 35%;
    // padding-left: 15px;

    .needToAcceptRules {
      text-align: center;
      border-radius: 5px;
      border: 1px solid $borderColor;
      padding: 40px 10px;
      font-weight: 500;
      color: $textcolor;
    }

    .checkBox {
      padding-top: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
      column-gap: 10px;
      cursor: pointer;

      .link {
        color: $statusNew;
      }
    }
  }
}

.notPartisipant {
  height: calc(40vh - 102px);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 26px;

  .text {
    font-size: 18px;
    font-weight: 600;
    color: $darkertext;
    text-align: center;
    padding-left: 20px;
  }
}

.modalRules {
  .scrollElem {
    // margin-right: 20px;
    padding: 20px 20px 0 40px;
    max-height: 85vh;
    overflow-y: scroll;
  };

  li {
    position: relative;
  }
  li::before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: -25px;
  }
  
  /* 1-й уровень */
  ol {
    counter-reset: num;
  }

  ol > li {
    counter-increment: num;
    font-weight: 600;
    padding-top: 20px;
  }

  ol > li::before {
    padding-top: 20px;
    content: counter(num) '.';
  }
  
  /* 2-й уровень */
  ol ol {
    counter-reset: num2;
  }

  ol ol > li {
    counter-increment: num2;
    font-weight: 400;
    padding-top: 20px;
  }

  ol ol > li::before {
    padding-top: 20px;
    content: counter(num) '.' counter(num2) '.';
  }

  /* Не нумерованный список */
  ul > li {
    font-weight: 400;
    padding-top: 20px;
  }
};

.signingCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
  
  .signingCardTilte {
    padding: 0 10px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: $textcolor;

    div {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .signingTilte {
    padding: 0 10px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: $textcolor;
  }

  .singingTree {
    // min-height: 60px;
  }

  .actionButtons {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    
    .cancelButton {
      background-color: rgb(216, 94, 86);
      &:hover {
        background-color: rgb(216, 94, 86, .8) !important;
      }
    }
  }

  .sendForSigning {
    height: 40px;
    width: 100%;
    // margin-top: 40px;
  }
}

.passwordModal {
  div[class='ant-modal-body'] {
    padding: 30px 30px 10px 30px;
  }
}

.table {
  width: 100%;
  height: calc(90vh - 102px);
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  .tableTitleWrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .tableTitle {
    font-size: 20px;
    font-weight: 600;
    color: $textcolor;
    // padding-bottom: 15px;
    text-align: center;
  }

  .tableWrap {
    min-height: 200px;
  }
}


.textToCenter{
  text-align: center !important;
};


.liItem {
  padding: 4px !important;

  .liButton {
    color: $textcolor !important;
    width: 100%;
    text-align: start;
    justify-content: flex-start;

  }

  &:not(:first-child) {
    padding-top: 4px !important;
  }
}

.decline {
  padding: 20px;

  &_item {
    margin-bottom: 0px;
  }

  &Button {
   background-color: red; 
   &:hover {
    background-color: $powerPoint !important; 
   }
  }
}


.messages {  
  &_overlay {
    height: auto;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid $borderColor;
    background-color: $hover100;
  }

  &_card {
    height: 60px;
    overflow-y: scroll;
    border: 1px solid $borderColor;
    border-radius: 3px;
    margin: 10px;
    display: flex;
    
    div[class='ant-card-body'] {
      padding: 4px;
    }
  }
}

.noSingWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  height: 200px;
}
