@import '../../../scss/variables/colors.module.scss';

.modalAddOrg{
  width: 70vw !important;

  div[class=ant-modal-body]{
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    padding: 10px 0px 10px 10px;
  }

  .loaderWrap {
    width: calc(100% - 15px);
    overflow: hidden;
    height: 70px;
    margin: 8px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid $borderColor;

    &:not(:last-child) {
      margin-bottom: 16px;
    };
  }
}
