@import "../../scss/variables/colors.module.scss";

.viewerWrap {
  height: 100vh;
  overflow: hidden;
  
  .viewerBody {
    height: calc( 100vh - 65px );
    background-color: $borderColor; 
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .headerWrapper {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px !important;
    background-color: white !important;
  
    .title {
      padding: 0 10px 0 0;
      font-weight: 600;
      font-size: 22px;
      line-height: 29px;
      text-transform: uppercase;
      height: 32px;
      color: Black;
      animation: ani 0.5s forwards;
    }
  
    .badge {
      white-space: nowrap;
      font-weight: 400;
      height: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 18px 12px 5px;
      gap: 12px;
      border-radius: 30px;
      background-color: $verylightgrey;
      transition: 0.2s all linear;
  
      &:hover {
        cursor: pointer;
        color: $primary100 !important;
        background-color: $hover100 !important;
      }
  
      .loginText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.filename {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100vw - 250px);

  div {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $darkertext;
    line-height: 20px;
    text-align: center;
  }
}

.errorWrap {
  height: calc(100vh - 65px);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 24px;
  background-color: $hovergrey;

  .errortext {
    padding-bottom: 10px;
  }
}

.shareBrowserModal {
  width: 60vw !important;

  div[class=ant-modal-body]{
    padding: 40px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
}
