@import '../../scss/variables/colors.module.scss';

.tableArea {
  height: calc(100vh - 260px);
  padding: 15px;
  overflow: hidden;
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.processLoading {
  cursor: progress;
  user-select: none;
}

.simpleIcon {
  cursor: pointer;
}

.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;

  &_item {
    position: relative;
    border-radius: 5px;
    text-align: center;
    user-select: none;
    transition: 0.3s all linear;
    width: 100px;
    overflow: hidden;

    p {
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      transition: 0.3s all linear;
    }

    &[data-clicked='true'] {
      background-color: $primary100 !important;
      color: #fff !important;

      span {
        color: #fff !important;
      }

      .conditentialIcon {
        background-color: $primary100 !important;
      }
    }

    .conditentialIcon {
      position: absolute;
      top: 3px;
      left: 8px;
      background-color: white;
      height: 24px;
      width: 24px;
      display: flex;
      font-size: 22px;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      transition: 0.3s all linear;
      
      span {
        color: red !important;
      }
    };

    &:hover {
      background-color: $hovergrey;
      color: #84858e ;
    }
  }
}

.sideIcons {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon {
  font-size: 42px;
  display: flex;
  justify-content: space-around;
  padding: 10px;

//   &_folder {
//     color: $primary100;
//   }

//   &_word {
//     color: #3770c3;
//     margin-right: 5px;
//   }

//   &_excel {
//     color: #087e40;
//     margin-right: 5px;
//   }

//   &_powerPoint {
//     color: #bf3513;
//     margin-right: 5px;
//   }

//   &_pdf {
//     color: #f15742;
//     margin-right: 5px;
//   }
}

.tableRowLoading {
  cursor: progress;
  user-select: none;
}

.onDrag {
  transition: 0.3s all linear;
  opacity: 0.5;
}

.onDragLeave {
  opacity: 1;
}

