@import '../../../scss/variables/colors.module.scss';

.modalDetails {

  div[class="ant-modal-header"] {
    padding: 5px;
  };

  button[class="ant-modal-close"] {
    top: 12px;
  };

  .tabsWrap {
    padding: 20px;
  }

  .notesWrap {
    padding-top: 20px;
    height: calc(90vh - 220px);
    overflow-y: scroll;
  }

  .simpleWrap {
    padding-top: 20px;
    max-height: calc(90vh - 44px - 45px);
    overflow-y: scroll;
  }

  .agreemensBlocks {
    display: flex;
    flex-direction: column;
  }

  .agreement_card {
    border-radius: 4px !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid $primary100;
    transition: 0.3s all linear;
  }

  .agreement_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;

    .lightGreen {
      color: lightgreen
    }
    
    button {
      width: 45%;
    }
  }


  .noAgreement {
    padding: 15px;
    text-align: center;
    // border-bottom: 1px solid $borderColor;
    color: black;
  }
  
  .argeementsLoading {
    gap: 40px;
    padding: 15px;
    display: flex;
    justify-content:flex-start;

    flex-direction: row;
    // border-bottom: 1px solid $borderColor;
    color: black;
  }

  .agreement {
    &_switchWrap {
      padding: 5px 10px;
      border-bottom: 1px solid $borderColor !important;
    }

    &_selectWrap {
      padding: 15px 10px;

      div[class*=ant-select] {
        border-radius: 4px !important;
      }
    }

    &_cancelButton {
      background-color: rgb(216, 94, 86);
      &:hover {
        background-color: rgb(216, 94, 86, .8) !important;
      }
    }

    &_sendButton {
      margin-left: 10px;
      width: calc(20% - 10px);
    }

    &_titleSpan {
      margin-left: 10px;
    }
  }
}

.modalTitleIcon {
  padding: 5px 0;
  font-size: 22px;
};

.textToCenter{
  text-align: center !important;
};

.checkGreen {
  color: green;
}

.closeRed {
  color: red;
}

.glassWaiting {
  color: #faad14;
}

.largeIcon {
  font-size: 24px;
}

.smallIcon {
  font-size: 12px;
}

.decline{
  padding: 20px;

  &_item{
    margin-bottom: 0px;
  }

  &Button{
   background-color: red; 
  }
}

.agreement_footer {
  display: flex;
  gap: 10px;
  height: fit-content;
}

.messages{
  width: 400px;
  height: auto;
  
  &_overlay{
    height: auto;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid $borderColor;
    background-color: $hover100;
  }

  &_card{
    border: 1px solid $borderColor;
    border-radius: 4px;
    margin: 10px;
    
    div[class='ant-card-body']{
      padding: 4px;
    }
  }
}

.subTitle {
  font-weight: 600;
  margin-bottom: 10px;
}

.messageTitle {
  line-height: 32px;
  vertical-align: middle;
  margin-bottom: 10px;
  font-weight: 600;
  min-height: 32px;
}

.table{
  width: 100%;
  height: fit-content;

  .tableTitleWrap {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .title {
      font-weight: 600;
    }
  }
}

.liItem {
  padding: 4px !important;

  .liButton {
    color: $textcolor;
    width: 100%;
    justify-content: flex-start;
  }

  &:not(:first-child) {
    padding-top: 4px !important;
  }
}


.noAgreementWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  height: 200px;
}
