@import '../../scss/variables/colors.module.scss';

.infoWrap {
  width: 100%;
  height: calc(100vh - 65px);
  padding: 15px;

  .info {
    background-color: white;
    border-radius: 6px;
    border: 1px solid $borderColor;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .paymentIcon {
      font-size: 122px;
      color: $statusResolved;
      padding-bottom: 35px;
    }

    .text {
      font-size: 20px;
      font-weight: 500;
      width: 75%;
    }

    .actions {
      padding-top: 10px;
      width: 75%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .primary {
        font-size: 16px;
        color: $primary100;
        font-weight: 600;
        text-decoration: underline;
      } 
    }

  }
}
