.wrapper {
  position: relative;
  width: 100%;
  padding: 15px;
  height: calc(100vh - 118px);
  overflow-y: scroll;
}

.card_group{
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.saveButton{
  max-width: 150px;
  text-align: center;
  position: fixed;
  bottom: 15px;
}
