@import '../../scss/variables/colors.module.scss';

.tableArea {
  background-color: $hovergrey;
  overflow: hidden;
  
  thead[class*="ant-table-thead"] th{
    background-color: white;
  }

  td[class*="ant-table-cell-scrollbar"]{
    background-color: white !important;
    box-shadow: none !important;
  }
}

.somePadding {
  padding: 15px 15px 0 15px;
}

.noPadding {
  border-top: 1px solid $borderColor;
  padding: 0px;
}

.permisssionsRow {
  td[class*="ant-table-cell-row-hover"]{
    background-color: $hovergrey !important;
  }
}

tr:has(div.rootFolder) {
  background-color: $verylightgrey !important;
}

.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  color: black;

  div {
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
  }

  .icon {
    font-size: 28px;
    font-weight: 900;
  }
}

.hovered {
  &:hover {
    cursor: pointer;
  }
}

.nonHovered {
  &:hover {
    cursor: not-allowed;
  }
}

td:has(div.hovered) {
  &:hover {
    .icon {
      opacity: .5;
    }
  }
}
