@import '../../scss/variables/colors.module.scss';

.cardSide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 6px;
  background-color: white;
  border: 1px solid $borderColor;

  &:hover {
    cursor: pointer;
    background-color: $hover100;
  }


  &:not(:first-child) {
    margin-top: 25px;
  }
  @media (min-width: 1301px) {
    width: 60%;
  }
  @media (min-width: 901px) and (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  
  .cardHeader {
    padding: 10px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid $borderColor;

    .cardTitle {
      font-weight: 500;
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .carfInfo {
    display: flex;
    flex-direction: column;
    padding: 10px 20px 20px 20px;
    width: 100%;


    .cardRow {
      padding: 10px;
      width: 100%;
      display: flex;

      .rowTitle {
        width: 200px;
      }

      .rowInfo {
        width: calc(100% - 200px);
      }
    }
  }
}

.selected {
  background-color: $hover200;
}

.fullwidth {
  width: 100% !important;
}

.link {
  color: blue;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: rgb(93, 93, 255)
  }
}
