@import '../../../scss/variables/colors.module.scss';

.roomCardsWrap {
  height: calc(100vh - 168px);
  overflow-y: scroll;
}

.actions {
  width: 100%;
  text-align: right;
  padding: 0;
}

.room div[class='ant-card-body'] {
  padding: {
    top: 12px;
    bottom: 12px;
  }
}

.room {
  margin-left: 9px;
  height: 72px;
  overflow: hidden;
  border: 1px solid $borderColor;
  border-radius: 5px;
  padding: 6px;

  &:not(:first-child) {
    margin-top: 12px;
  }
  &:last-child {
    margin-bottom: 12px;
  }

 .roomContent {
    display: flex;
    flex-direction: column;
    row-gap: 3px;

    .jusctifySpace {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      
      .roomTitle {
        font-weight: 500;
        overflow: hidden;
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 3px 10px 5px 5px;
        
        .iconNDA {
          color: red;
          margin: 0;
          padding: 0 2px 0 0;
          font-size: 16px;
        }

        .iconClock {
          color: orange;
          margin: 0;
          padding: 0 2px 0 0;
          font-size: 16px;
        }

        .nameOverflow {
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
        }
      }
    }


    .iconBtn {
      padding: 4px;
      border-radius: 6px;
      font-size: 20px;
      margin-left: 5px;
      cursor: pointer;
      transition: 0.3s all linear;
      &:hover {
        color: $primary100;
        background-color: $hover100;
      }
    }

    .roomDescription {
      min-height: 28px;
      display: inline-block;
      font-size: 12px;
      color: $textcolor;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 10px 0 5px;
      vertical-align: middle;
      line-height: 28px;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $hover200;
  }
}

.activeRoom {
  background-color: $primary100;
  color: white;

  .primary{
    color: white;
  }

  .roomDescription {
    color: $borderColor !important;
  }

  &:hover {
    cursor: default;
    background-color: $primary100;
  }
}

.awaiting {
  background-color: $hovergrey;
}

.cursor {
  &:hover {
    cursor: pointer;
  }
}

.loadingRoom {
  &:hover {
    cursor: progress;
  }
}

.management {
  display: flex;
  justify-content: space-between;
  margin: 10px;

  &_searchIcon {
    color: $textcolor;
  }

  &_plus {
    margin-left: 10px;
  }

  @media screen and (max-width: 960px) {
    &_plus {
      display: none;
    }
  }
}


.emptyCreate {
  margin-top: 20px;
}

.mark {
  padding: 0;
  background-color: yellow;
}

.badges {
  display: flex;
  gap: 4px;
}


.menuActions{
  display: flex;
  align-items: center;
  height: 100%;

  .info{
    // cursor: help;
  }
}

.new{
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: $statusResolved;
  padding: 0 3px;
}
