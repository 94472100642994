@import '../../../scss/variables/colors.module.scss';

.modalAddRoom {
}

.modalWrap {
  width: 100%;
  height: auto;
  max-height: 70vh;
  // height: 37vh;
  display: flex;
  background-color: $hover100;
}

.form {
  width: 100%;
  padding: 15px 30px 0 30px;
}

.switch_wrap {
  height: 22px;
  
  .switch_title {
    padding-left: 10px;
  }
}

.checkBoxes {
  display: flex;
  flex-direction: column;
}

.productCard {
  background-color: white;
  border: 1px solid $borderColor;
  border-radius: 8px;
  padding: 10px;
  // color: $textcolor;
  font-weight: 16px;

  .title {
    font-weight: 18px;
    padding: 10px 0 10px 0;
    font-weight: 600;
    text-align: center;
  }

  .row {
    padding: 8px;
    display: grid;
    grid-template-columns: 2fr 2fr;

    .name {
      font-weight: 500;
    }
  }
}

.licenseButton {
  .link {
    color: $statusNew;
  }
}
