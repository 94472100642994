@import '../../scss/variables/colors.module.scss';


.research {
  display: flex;
  flex-direction: column;
  height: calc(100% - 45px);
  display: flex;
  gap: 15px;
}

.dragger {
  flex-grow: 3;
}

.uplaodList {
  border: 1px solid $borderColor;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div[class*=ant-card-body] {
    height: 115px;
  };

  div[class*=ant-card-head-title] {
    text-align: center;
    font-size: 16px;
  };
}

.fileItem {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  font-size: 35px;
  border: 1px solid $borderColor;
  align-items: stretch;
  border-radius: 4px;
  
  &_icon{
    border-right: 1px solid $borderColor;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  &_name {
    word-break: break-all;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_deleteIcon{
    color: red;
    transition: 0.3s all linear;
    display: flex;
    align-items: center;
    border-left: 1px solid $borderColor;
    padding: 15px;
    justify-content: center;

    &:hover{
      color: tomato;
      cursor: pointer;
    }
  }
}

.researchButton {
  width: inherit;
  padding: 5px 15px;
}


.info{
  height: calc(100vh - 217px);
  border: 1px solid $borderColor;
  border-radius: 4px;
  // margin-top: 15px;
  padding: 15px;
}

.action{
  border: 1px solid $borderColor;
  border-radius: 4px;
  // margin-top: 15px;
}

.emptyText{
  height: calc((100vh - 375px) / 2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoTable {
  height: calc((100vh - 260px) / 2);
}

.compareTable {
  height: calc((100vh - 260px) / 2);
  tr {
    td:has(div.redCell) {
      background-color: $changed;
    }

    &:hover {
      td:has(div.redCell) {
        background-color: $changed !important;
      }
    }
  }
}
