.wrap {
  // display: flex;
  // align-items: center;
  // justify-self: center;
  // flex-direction: column;
  // height: 65px;

  // .switch_wrap {
  //   padding-bottom: 10px;
  // }
  .select {
    min-width: calc(100% - 55px);
  }
  
  .choosenArea {
    margin-right: 10px;
  }
}
