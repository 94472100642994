@import '../../scss/variables/colors.module.scss';

.managementArea {
  height: 60px;
  overflow: hidden;
  border-bottom: 1px solid $borderColor;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;

  .managementWrap {
    height: 60px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: white;
  }

  .managementTitle {
    height: 40px;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 15px;
    font-weight: 600;
    font-size: 16px;
  }

  .optionsSection {
    display: flex;
    align-items: center;
    gap: 10px;

    .rangePicker {
      width: 400px;
    }
  }
}

.liItem {
  padding: 4px !important;

  .liButton {
    color: $textcolor;
    width: 100%;
    text-align: start;
  }

  &:not(:first-child) {
    padding-top: 4px !important;
  }
}


// div[class*=ant-dropdown] {
//   li {
//     padding: 0 !important;
//   }
// }
