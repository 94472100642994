@import '../../scss/variables/colors.module.scss';

.title {
  width: 214px;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  height: 32px;
  color: Black;
  animation: ani 0.5s forwards;
}

.headerWrapper {
  z-index: 990;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px !important;
  background-color: white !important;
  border-bottom: 1px solid $borderColor;

  .buttonAndName {
    display: flex;
    align-items: center;

    .button {
      &:hover {
        color: $primary100;
      }
    }

    .roomName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 600;
      max-width: calc(100vw - 640px);
      // text-transform: uppercase;
    }
  }
}

.notification {
  position: relative;

  div[class='ant-tabs-nav-operations'] {
    display: none !important;
  }

  &Wrap {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid $borderColor;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: 0.2s all linear;
    &:hover {
      background-color: $hover100 !important;
    }
  }

  &Bell {
    font-size: 20px;
    transition: 0.2s all linear;
  }

  &:hover &Bell {
    color: $primary100 !important;
  }

  &_container {
    position: absolute;
    left: 50%;
    top: 50px;
    border-radius: 20px;
    border: 1px solid $borderColor;
    width: 350px;
    max-height: 80vh;
    z-index: 990;
    padding: 20px;
    background-color: rgba(255, 255, 255);
    display: none;
    animation: scaleY 0.3s forwards;
    transform: translateX(-50%) scaleY(0);
    transform-origin: top;
  }

  &_items {
    height: 100%;
    overflow-y: auto;
  }

  .leftIcon {
    padding-right: 5px;
    cursor: pointer;
  }

  .rightIcon{
    padding-left: 5px;
    cursor: pointer;
  }
}

.notification_container.notification_open {
  display: block;
}

@keyframes scaleY {
  0% {
    transform: translateX(-50%) scaleY(0);
  }

  100% {
    transform: translateX(-50%) scaleY(1);
  }
}

.userBadge {
  white-space: nowrap;
  font-weight: 400;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 18px 12px 5px;
  gap: 12px;
  border-radius: 30px;
  background-color: $verylightgrey;
  transition: 0.2s all linear;

  &:hover {
    cursor: pointer;
    color: $primary100 !important;
    background-color: $hover100 !important;
  }
}

.hoverable {
  cursor: pointer;

  &:hover {
    background-color: $hover100;
  }
}

.list_item {
  border-bottom: 1px solid $borderColor;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.3s all linear;

  &_title {
    display: flex;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px !important;
    gap: 5px;
  }

  &_inner_title {
    text-overflow: ellipsis !important;
    text-wrap: nowrap !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }

  &_timestamp {
    font-size: 0.6em;
    text-wrap: nowrap !important;
    white-space: nowrap !important;
    color: #ccc;
  }

  &_buttons {
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;

    button {
      font-size: 13px;
      padding: 3px 16px;
      max-height: 24px;

      span {
        line-height: 14px;
      }
    }
  }

  &_description {
    line-height: 17.5px;
    overflow: hidden;
    height: 64px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_dot {
    color: red;
  }
}

.list_button {
  margin: 10px auto 0;
  display: block;
}

.userText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.labelPoint {
  color: $textcolor !important;

  &:hover {
    color: $primary100 !important;
    background-color: $hover100 !important;
  }
}

.roomsPopover {
  .popoverInputIcon {
    color: lightgrey;
    font-size: 18px;
  }

  .controlButtons {
    .exactButton {
      margin-top: 10px;
      color: $darkertext !important;

      &:hover {
        color: $primary100 !important;
      }
    }
  }

  ::-webkit-scrollbar-track {
    background-color: $hover100;
  }

  ::-webkit-scrollbar-thumb {
    border-color: $hover100;
  }

  .roomGroup {
    .roomGroupTitle {
      color: $darkertext;
      padding-top: 20px;
    }

    .group {
      max-height: 170px;
      overflow-y: scroll;
    }

    .roomButton {
      overflow: hidden;
      text-align: start;
      // width: calc(100% - 10px);
      width: 100%;
      margin-top: 10px;
      background-color: $hover100 !important;
      color: $darkertext !important;

      &:hover {
        border-color: $primary100 !important;
      }
    }
  }

  .templatesGroup {
    padding-bottom: 40px;
  }

  .showAll {
    margin-top: 40px;
    text-decoration: underline;
    color: $primary100;
    text-align: center;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.noRooms {
  color: $textcolor;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  height: 30px;
}

.profile {
  display: flex;
  align-items: center;
  gap: 20px;
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px !important;
  padding: 15px;
  width: 100%;
  border-bottom: 1px solid $borderColor;
  background-color: white !important;

  &_title {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    // width: 100%;
    overflow: hidden;
    font-size: 16px;
    font-weight: 600;
    color: #000000;

    .changeInput {
      max-width: 100%;

      input[class*='ant-input'] {
        font-size: 16px;
        font-weight: 600 !important;
      }

      span[class*=ant-input-show-count-suffix]{
        font-weight: 400;
        font-size: 12px;
      }
    }

    .blockName {
      display: flex;
      align-items: center;
      flex-direction: row;

      .editIcon {
        color: $primary100;
        font-size: 16px;
        &:hover {
          opacity: 0.7;
        }
      }

      .editButton {
        visibility: hidden;
      }

      &:hover {
        &:has(div.editButton) {
          cursor: pointer
        }

        .editButton {
          visibility: visible;
        }
      }
    }
  }

  .hideButton {
    visibility: hidden;
  }

  &_icons {
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    height: 32px !important;
    gap: 12px;
  }

  &_trial {
    color: black;
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-size: 14px;
    font-weight: 500;
    text-wrap: nowrap;
  }

  &_segment {
    label[class*='ant-segmented-item-selected'] {
      color: #ffffff;
      background-color: $primary100;
    }
    label[class='ant-segmented-item'] {
      color: $textcolor;
      transition: none;
      &:hover {
        color: $textcolor !important;
        opacity: 0.7;
      }
    }
    div[class='ant-segmented-item-label'] {
      display: flex;
      align-items: center;
    }
  }

  &_logo {
    background-image: url('../../assets/img/logo_cut.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 40px;
    width: 200px;
  }

  span[class*='ant-input-affix-wrapper'] {
    padding-right: 5px;
    padding-left: 0;
  }
  span[class*='ant-input-group-addon'] {
    padding: 0;
  }

  .saveButton {
    color: $success;
    font-size: 16px !important;

    &:hover {
      opacity: 0.6;
    }
  }

  .cancelButton {
    color: $textcolor;

    &:hover {
      opacity: 0.6;
    }
  }
}

.iconMenu {
  font-size: 20px !important;
}
