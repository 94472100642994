@import '../../scss/variables/colors.module.scss';

.card {
  // width: 600px;
  border-color: #e2e8f0;
  box-shadow: 0 8px 13px -3px rgba($color: #000000, $alpha: 7%);
  border-radius: 2px;

  div[class='ant-card-body']{
    padding: 15px;
  }

  div[class='ant-card-head']{
    padding: 15px;
  }
}

.avatar {
  margin-right: 10px;
}

.avatarItems {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  div {
    margin: 0 !important;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
}

.ico {
  z-index: 10;
  position: absolute;
  top: 38px;
  left: 1rem;
}

.input {
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #7f56d8;

  input {
    background: rgba(127, 86, 216, 0.005);
    font-weight: 600;
    font-size: 16px;
  }
}

.select {
  height: 40px;
  font-size: 16px;
  font-weight: 600;

  div[class='ant-select-selector'] {
    border: 1px solid #7f56d8 !important;
  }

  span[class='ant-select-selection-item'] {
    font-weight: 600 !important;
  }
}

.container {
  margin: 1rem;

  &_circle {
    margin: 0 auto;
    width: 4rem;
    height: 4rem;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    padding: 1rem;
    border-radius: 50%;
  }
}

.form_item{
  margin-bottom: 4px;
}
