@import "../../scss/variables/colors.module.scss";

.bookWrap {
  padding-top: 50px;
  height: 100vh;
}

.bookWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  font-family: 'Roboto';
  margin-top: 20vh;
  width: 100%;
}

.bookTitle {
  display:flex;
  justify-content: center;
  font-size: 24px;
  margin-bottom: 16px;
}

.bookCard {
  width: 50%;
  border: 1px solid grey;
  margin-bottom: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  color: inherit;
  padding: 16px 32px;
  align-items: center;
  cursor: pointer;
}

.bookCard:hover {
  opacity: 0.7;
}

.bookCard, .bookCard:link, .bookCard:visited {
  text-decoration: none;
}

.bookCard:hover {
  text-decoration: none;
}

.bookBtn {
  background-color: #4267B2;
  padding: 8px 16px;
  color: #ffffff;
  border-radius: 8px;
}

.bookUser {
  font-size: 16px;
  font-weight: 600;
  color: #4267B2;
}

.bookDescription {
  font-size: 12px;
  color: grey;
}

.backBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  border: 1px solid rgba(26, 26, 26, 0.1);
  border-radius: 50%;
  background-clip: padding-box;
  color: rgb(66, 103, 178);
  font-size: 24px;
  background-color: transparent;
}

.backBtn:hover {
  background-color: rgba(0, 105, 255, 0.15);
}

.back {
  width: 24px;
  height: 24px;
  color: rgb(66, 103, 178);
}