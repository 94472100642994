@import "../../../scss/variables/colors.module.scss";

.body{
    padding: 0px 20px;
    max-height: calc(80vh - 60px);
    overflow-y: auto;
}

.modal{
    width: 50vw !important;
}

.row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $borderColor;
    padding-top: 20px;
    padding-bottom: 20px;
}

.column{
    width: 50%;
}
