@import '../../../scss/variables/colors.module.scss';


.card {
  margin: 15px 0;

  div[class*=ant-card-head-title] {
    color: $textcolor !important;
  }
}

.file {
  position: relative;
}

.deleteIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s all linear;
  font-size: 1.2em;

  &:hover {
    color: $primary100;
  }
};

.canEdit {
  grid-template-columns: 10fr 5fr 1fr;
};

.cantEdit {
  grid-template-columns: 10fr 5fr;
};

.uploadedWrap {
  width: 100%;
  display: grid;
  gap: 12px;
  padding: 0px;

  .cardNDA {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
    height: 66px;
    border-radius: 6px;
    border: 1px solid $borderColor;

    &:hover {
      cursor: pointer;
      background-color: $hover200;
    }

    .cardView {
      font-size: 22px;
      color: $primary100
    }
  }
}


.dragger {
  display: block;
  max-width: 600px;
  overflow: hidden;
  margin: 30px auto;

  div[class='ant-upload-drag-container'] {
    padding: 20px;
  }
}


.statusTermWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;

  .acceptIcon {
    padding: 0px 10px;
    font-size: 28px;
    color: $statusResolved;
  }

  .notAcceptIcon {
    padding: 0px 10px;
    font-size: 28px;
    color: $png;
  }

  .rejectIcon {
    padding: 0px 10px;
    font-size: 28px;
    color: $powerPoint;
  }

  .nonAccept {
    color: $png;
  }

  .accept {
    color: $statusResolved;
  }

  .reject {
    color: $powerPoint;
  }
}

.exclamationIcon {
  font-size: 22px !important;
  color: $png;
  padding-right: 6px;
}

.titleRow  {
  display: flex;
  align-items: center;
  justify-content: center;
}

.textModal {
  font-size: 16px;
  font-weight: 400;
}
