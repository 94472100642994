@import "../../../scss/variables/colors.module.scss";

.toolbar{
    height: 40px;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.divider{
    height: 60%;
    width: 2px;
    border-inline-start-width: 2px; 
}

.pageCount{
    width: 60px;
}

.close {
    color: red;
    position: absolute;
    right: 20px;
    font-size: 24px;
}

.downloadBtn {
  color: $darkertext;
  font-weight: 600;
}
