@import '../../../scss/variables/colors.module.scss';

.chatTab {
  &_item {
    margin-left: 9px;
    height: 72px;
    overflow: hidden;
    border: 1px solid $borderColor;
    border-radius: 5px;
    padding: 6px;
    transition: 0.3s all linear;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 12px;
    }

    &:last-child {
      margin-bottom: 12px;
    }

    &_actions {
      width: 25px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &_amount {
      background-color: #d9d9d9;
      color: #fff;
      font-size: 10px;
      border-radius: 25px;
    }
  }

  &_title {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

    &_description {
      color: $descriptioncolor;
      font-weight: normal;
    }

    &_inner {
      padding: 3px 0 5px 0;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &_item.active {
    background-color: $primary100;
    color: #fff !important;

    & div[class*='description'] {
      color: #fff !important;
    }

    .title {
      color: #fff !important;
    }

    .chatTab_item_amount {
      color: $primary100;
      background-color: #fff;
    }
  }

  &_tabs {
    overflow-y: scroll;
    height: calc(100vh - 224px);
    padding-bottom: 20px;
  }
}

.rejected_amount{
  color: red;
}

.create {
  color: $primary100;
}

.management {
  display: flex;
  margin: 10px;

  &_create {
    background-color: $primary100;
  }

  &_searchIcon {
    color: $textcolor;
  }

  &_plus {
    margin-left: 10px;
    svg {
      font-size: 22px;
    }
  }
}

.mark {
  padding: 0;
  background-color: yellow;
}

.segment{
  width: 100%;
  padding-right: 20px;
  label[class*='ant-segmented-item']{
    width: 100%;
  }
}