@import '../../scss/variables/colors.module.scss';

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .mainSpace {
    height: calc(100vh - 155px);
    border-radius: 4px;
    background-color: white;
    margin: 15px;
  }
}
