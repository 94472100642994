@import "../../scss/variables/colors.module.scss";

.button {
  border: 0px;
  width: 100%;
  // text-align: start;
  border-radius: 0;
  font-weight: 600;
  justify-content: flex-start;
  // border-radius: 6px;

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.hovered {
  &:hover {
    background-color: $hover100;
  }
}
