@import '../../scss/variables/colors.module.scss';

.sider {
  // ::-webkit-scrollbar {
  //   display: none;
  // }
  border-left: 1px solid $borderColor;
  border-top: 1px solid $borderColor;
}

.siderMenu {
  height: calc(100vh - 66px);
  background-color: #babac0;
}

.aside {
  position: relative;
  display: flex;
  flex-direction: column;

  &_close {
    z-index: 1000;
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.accordion {
  border-radius: 0;

  .panel {
    font-weight: 500;
    background-color: white;
  }

  div[class*='ant-collapse-content-box'] {
    padding: 0 !important;
  }
}

.contentArea {
  min-height: 300px;
  max-height: calc(100vh - 112px);

  background-color: white;
  border-bottom: 1px solid $borderColor;
  overflow-y: scroll;

  .documentsTitle {
    padding: 5px;
    text-align: center;
    font-weight: 600;
    color: $darkertext;
  }

  .documentWrap {
    color: $darkertext;
    padding: 5px 5px 5px 20px;
    display: grid;
    grid-template-columns: 3fr 20px;
    gap: 12px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: $primary100;
    }

    .oneDocument {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .downloadIcon {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .holder {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.tabs{
  margin-top: 35px;
  padding: 10px;
}

.downloadingProcess {
  cursor: progress;
}
