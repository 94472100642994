@import '../../../scss/variables/colors.module.scss';

.createFolderModal{
  position: relative;
  text-align: center !important;
  
  .expandIcon {
    color: $textcolor !important;
    font-size: 14px !important;
  }
  
  div[class*=ant-collapse] {
    // border-radius: 0px;
    // border: 0px;
  }

  div[class*=ant-collapse-item] {
    // background-color: white;
    border-top: 1px solid $borderColor !important;

    span[class*=ant-collapse-header-text] {
      color: $textcolor;
      font-weight: 500;
    }

    div[class*=ant-collapse-content-box] {
      padding: 0px !important;
    }
  }
}

.transition {
  transition: .2s all linear;
}

.folderName{
  width: 80%;
  margin: 50px auto;
}
