@import "../../../scss/variables/colors.module.scss";

.scheduleWrap {
  background-color: white;
  margin-top: 15px;
  overflow: hidden;
  border: 1px solid $borderColor;
  border-radius: 4px;
  height: calc(100vh - 198px);
}

.scheduleTable {
  height: 100%;
  border-radius: 5px;
  padding: 15px;
  background-color: white;
}
