@import "../../scss/variables/colors.module.scss";

$lg: 54px;

.mainWrap {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image {
  width: 35%;
  height: 100vh;
  background: #c7b39b url("../../assets/img/unsplash_E2i7Hftb_rI.png");
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &_title {
    color: #ffffff !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.loginElem {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; 
  position: relative;
  
  .localizationSelect {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

@media screen and (max-width: 960px) {
  .loginCard {
    width: 80%;
  }
}

@media screen and (min-width: 960px) {
  .loginCard {
    width: 30%;
  }
}

.qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    border-radius: 12px;
  }
}

.loginForm {
  button[type="submit"] {
    width: 100%;
    height: $lg;
    background-color: $primary100;
    color: #ffffff;
    font-size: 20px;
    border-radius: 10px;
  }

  .input {
    font-size: 20px;
    height: $lg;
    padding: {
      left: 20px;
      right: 20px;
    }
    border: 3px solid $primary100;
    border-radius: 10px;

    input {
      padding-left: 10px;
    }
  }

  .input:hover {
    border: 3px solid $primary100;
  }
}

.subTitle {
  color: #303468;
}

.passwordSubtitle {
  transform: translateY(-100%);
  text-align: right;

  a {
    text-decoration: underline;
    color: #8b8b8b;
    font-size: 12px;
  }
}

.textWrap {
  text-align: center;
  flex-direction: column;

  .primary {
    color: $primary100 !important;
    font-weight: 600;
    text-wrap: balance;
  }
}



.confirmWrap {
  padding: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .likeIcon {
    font-size: 124px;
    padding-bottom: 24px;
    color: $success;
    border: 7px solid $success;
    border-radius: 50%;
    padding: 48px;
  }

  .textConfirm {
    font-size: 20px;
    font-weight: 500;
    // color: $textcolor;
    padding-top: 40px;
    text-align: center;
  }
}

