@import '../../scss/variables/colors.module.scss';

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .mainSpace {
    height: calc(100vh - 95px);
    border-radius: 4px;
    margin: 15px;
  }

  .container {
    background-color: white;
    border: 1px solid $borderColor;
    border-radius: 4px;
  }
}

.tabs {
  margin: {
    left: 15px;
    right: 15px;
    bottom: 15px;
  }
  
  &_tab {
    display: flex;
  }
}

.resetButton {
  color: $textcolor;
  margin-right: 5px;
}

.liItem {
  padding: 4px !important;

  .liButton {
    color: $textcolor;
    width: 100%;
    text-align: start;
  }

  &:not(:first-child) {
    padding-top: 4px !important;
  }
}
