@import '../../scss/variables/colors.module.scss';

.managementArea {
  height: 110px;
  overflow: hidden;
  border-bottom: 1px solid $borderColor;
  background-color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .managementWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .managementBlock {
      height: 50px;
      padding: 10px 15px;
      width: 100%;
      border-bottom: 1px solid $borderColor;
      padding-bottom: 15px;

      .managementBlockTitle {
        line-height: 30px;
        white-space: nowrap;
        padding-left: 15px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        width: 100%;

        nav {
          width: calc(100% - 240px);
          overflow-y: scroll;

          ol {
            flex-wrap: nowrap;
          }
        }
      
        .entityName {
          color: $textcolor;
          vertical-align: middle;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }

      .managementSearch{
        width: 250px;
        height: 31px;
        font-size: 13px;
      }
    }

    ::-webkit-scrollbar {
      @media (min-width: 856px) and (max-width: 10000px) {
        display: none;
      }
      @media (max-width: 855px) {
        display: block;
      }
    }
    
    .managementButtons {
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 15px;
      display: flex;
      gap: 5px;
      justify-content: space-between;

      .managementMainButtons {
        display: flex;
        gap: 5px;
        justify-content: flex-start;

        .button {
          min-width: 140px !important;
        }
      }
    }
  }
}

.popover_item{
  cursor: pointer;
  transition: 0.3s all linear;
  
  &:hover{
    background-color: $hovergrey;
  }
}

.statusLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition-duration: .3s;

  &:hover {
    .red {
      color: $primary100;
      transition-duration: .3s;
    }
  }
}

.grey {
  color: $textcolor;
}

.buttonRed {
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    border: 1px solid $red !important;
  }
}

.red {
  color: $red;
  transition-duration: .3s;

}

.emptyRooms {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.RGroupButtons {
  display: flex;
  flex-direction: row;

  button {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}
