@import '../../../scss/variables/colors.module.scss';

.usersTab {
  padding-left: 10px;

  .usersWrap {
    height: calc(100vh - 165px);
    overflow-y: scroll;

    .loading {
      &:hover {
        cursor: progress;
      }
    };

    .userCard {
      height: 72px;
      overflow: hidden;
      border: 1px solid $borderColor;
      border-radius: 5px;
      padding: 6px;
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 12px;
      }
      &:last-child {
        margin-bottom: 12px;
      }

      &:hover {
        cursor: pointer;
        background-color: $hover200;
      }

      .jusctifySpace {
        display: flex;
        gap: 16px;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 1px 2px 2px 10px;

        // div[class*=ant-select-selector] {
        //   color: $textcolor;
        // }

        .deleteIcon {
          color: $textcolor;

          &:hover {
            color: $primary100;
          }
        }

        .userTitle {
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          font-weight: 500;
        }

        .userDescription {
          min-height: 28px;
          display: inline-block;
          font-size: 12px;
          color: $textcolor;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
          line-height: 28px;
        }
      }
    }
  }

  .noFound {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $textcolor;
    height: 100px;
  }
}

.conrolWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 7px;
  margin: 10px 10px 10px 0;
}

.aside {
  div[class*=ant-collapse-header] {
    padding: 5px !important;
  }
  div[class*=ant-collapse-content-box] {
    padding-left: 5px !important;
  }

  .title {
    padding-left: 40px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    font-size: 14px;
    overflow: hidden;
    height: 36px;
    line-height: 36px;
    vertical-align: middle;
    font-weight: 600;
  }

  position: relative;
  display: flex;
  flex-direction: column;

  &_close {
    z-index: 1000;
    position: absolute;
    top: 2px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.accordion{
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  height: calc(100vh - 260px);
  overflow-y: auto;
  background-color: white;

  div{
    border-radius: 0px !important;
  }
}

.mark {
  padding: 0;
  background-color: yellow;
}

.item {
  color: $textcolor;
  padding-bottom: 8px;
}

.itemValue {
  padding-left: 10px;
}

.itemTitle {
  font-weight: 600;
  padding-bottom: 8px;
  color: $textcolor !important;

  label {
    color: $textcolor !important;
  }
}  

.billButton {
  width: 150px;
  margin: 8px 0;
}

.serviceWrapper {
  padding: 0 8px;
  display: flex;
  gap: 32px;
}

.serviceTitle {
  font-size: 16px;
  color: $textcolor;
}

.serviceDescription {
  font-size: 12px;
  color: $descriptioncolor;
}

.serviceCost {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}


.segment{
  width: 100%;
  padding-right: 20px;
  label[class*='ant-segmented-item']{
    width: 100%;
  }
}
