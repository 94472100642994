@import "~antd/dist/reset.css";
@import "~react-leaflet-markercluster/dist/styles.min.css";
@import "~leaflet/dist/leaflet.css";
@import "./libs/normalize";
@import "./variables/colors.module.scss";

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
/* total width */
*::-webkit-scrollbar {
  background-color:#fff;
  width:16px;
  border-bottom-right-radius: 5px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color:#fff;
  border-bottom-right-radius: 5px;

}
*::-webkit-scrollbar-track:hover {
  background-color:#f4f4f4;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:5px solid #fff
}
*::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {display:none}

.ant-modal-content {
  padding: 0 !important;

  .ant-modal-header {
    border-bottom: 1px solid $borderColor !important;
    padding: 15px;
    margin: 0;
    background-color: $hovergrey;
    & .ant-modal-title {
        text-align: center;
    }
  }

  .ant-modal-footer {
    background-color: $hovergrey;
    border-radius: 0 0 8px 8px;
    margin: 0 !important;
    border-top: 1px solid $borderColor !important;
    padding: 10px !important;
  }
}

.ant-popconfirm {
  max-width: 700px;

  .ant-popconfirm-message-text {
    overflow: scroll !important;
    // text-overflow: ellipsis !important;
  }
}

// tr:has(div.selectedRow) {
//   background-color: $hover100 !important;

//   div.confidential {
//     background-color: $hover100;
//   }
// }

.ant-card {
  box-shadow: none !important;
}
