@import '../../scss/variables/colors.module.scss';

.emptyRooms {
  height: 100%;
  margin: 20px;
  border-radius: 4px; 
  border: 1px solid $borderColor;
}

.centred {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
