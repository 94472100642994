@import "../scss/variables/colors.module.scss";

.icon{
  margin-right: 5px;
  font-size: 22px;
  &_folder{
    color: $primary100;
  }

  &_word{
    color: $word !important;
  }

  &_excel{
    color: $excel !important;
  }

  &_powerPoint{
    color: $powerPoint !important;
  }

  &_pdf{
    color: $pdf !important;
  }
  
  &_youtube {
    color: $youtube !important;
  }
}
