.title {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 40px;
  font-weight: 600;
  font-size: 24px;
  color: black;
}

.profileBody{
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.backButton{
  transform: translateY(100%);
}