@import '../../../scss/variables/colors.module.scss';

.confident{
    text-align: center;
    cursor: pointer;
    padding: 20px;
    font-size: 1.5em;
    transition: 0.3s all linear;

    .word{
        color: $word;
    }

    &:hover{
        color: $primary100;
    }
}