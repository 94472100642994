@import '../../../scss/variables/colors.module.scss';

.modal {
  position: relative;
  div[class='ant-modal-body'] {
    padding: 20px;
    height: 80vh;
    overflow-y: scroll;
  }

  .wrap {
    .groupWrap {
      text-align: center;
      vertical-align: middle;
      font-weight: 600;
      font-size: 14px;
      padding-bottom: 12px;
    }

    &:not(:first-child) {
      .groupWrap {
        padding-top: 12px;
        border-top: 1.5px solid $borderColor;
      }
    }
  }
}


