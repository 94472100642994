@import '../../scss/variables/colors.module.scss';

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  .mainSpace {
    height: calc(100vh - 95px);
    border-radius: 4px;
    margin: 15px;
  }

  .container {
    background-color: white;
    border: 1px solid $borderColor;
    border-radius: 5px;
  }
}

.tabsEmptyText {
  height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.emptyCreate {
  margin-top: 20px;
}
