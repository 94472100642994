@import '../../scss/variables/colors.module.scss';

.tableWrapper {

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 15px;
  border-radius: 6px;
  background-color: white;
}

.centredCell {
  height: 100%;
  width: 100%;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    cursor: pointer;
    color: $primary100;
  }
}
