@import '../../scss/variables/colors.module.scss';

.wrap {
  position: relative;
  width: 100%;
  padding: 15px;
  overflow: hidden;
  height: calc(100vh - 120px);

  ::-webkit-scrollbar {
    width: 16px;
    background-color: #ffffff;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #ffffff;
  }
  
  .sidesTitle {
    display: flex;
    align-items: center;
    border: 1px solid $borderColor;
    border-bottom: 0;
    padding: 0 24px;
    min-height: 56px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    color: $textcolor;
    background-color: #ffffff;
  }

  .sidesArea {
    border: 1px solid $borderColor;
    // height: calc(100vh - 192px);
    height: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 25px;
    overflow-y: scroll;
    background-color: #ffffff;

    .emptySides {
      height: 207px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-radius: 6px;
      border: .5px dashed $borderColor;
      @media (min-width: 1301px) {
        width: 60%;
      }
      @media (min-width: 901px) and (max-width: 1300px) {
        width: 80%;
      }
      @media (max-width: 900px) {
        width: 100%;
      }
    }

    .addButton {
      margin-top: 25px;
      font-weight: 500;
    }
    
  }
}
