@import '../../scss/variables/colors.module.scss';

.mainDiv {
  overflow: hidden;
};

.resizing {
  user-select: none;
  cursor: col-resize !important;
}

.outletWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.spinner {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
