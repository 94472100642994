
.timeline {
  div[class*=ant-timeline-item-content] {
    margin-inline-start: 0;
    padding-left: 30px;
    width: calc(50% - 24px) !important;
  };

  div[class*=ant-tabs-tab] {
    margin: 0 16px !important;

    div[class*=ant-tabs-tab-btn]{
      margin: 0 !important;
    }
  }

  div[class*=ant-tabs-nav-operationstab] {
    padding: 0px !important;
  }  
};


