@import "../../scss/variables/colors.module.scss";

.infoWrap {
  // style={{width: '100%', height: 'calc(100vh - 65px)', fontSize: 18, fontWeight: 600}}
  width: 100%;
  height: calc(100vh - 65px);
  padding: 15px;

  .info {
    background-color: white;
    border-radius: 6px;
    border: 1px solid $borderColor;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .warningIcon {
      font-size: 122px;
      color: orange;
      padding-bottom: 25px;
    }

    .text {
      font-size: 20px;
      font-weight: 500;
      width: 75%;
    }

    .actions {
      padding-top: 5px;
      width: 75%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .primary {
        font-size: 16px;
        color: $primary100;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }

      .downloadBtn {
        font-weight: 500;
      }

      .downloadIcon {
        font-size: 16px;
      }
    }

  }

  .productCard {
    background-color: white;
    border: 1px solid $borderColor;
    border-radius: 8px;
    padding: 4px;
    margin-bottom: 20px;
    max-width: 800px;

    .title {
      font-size: 16px;
      padding: 10px 0 10px 0;
      font-weight: 600;
      text-align: center;
    }

    .row {
      padding: 4px;
      display: grid;
      grid-template-columns: 2fr 2fr;

      .name {
        font-weight: 500;
        font-size: 16px;
      }

      .text {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
