@import '../../../scss/variables/colors.module.scss';

.tabWrap {
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.rowWrap {
  display: flex;
  align-items: center;
  text-wrap: wrap;

  span {
    text-wrap: wrap;
  }
}

.acceptIcon {
  padding-right: 5px;
  font-size: 18px;
  color: $statusResolved;
}

.rejectIcon {
  padding-right: 5px;
  font-size: 18px;
  color: $powerPoint;
}

.notAcceptIcon {
  padding-right: 5px;
  font-size: 18px;
  color: $statusProgress;
}

.accept {
  font-weight: 500;
  color: $statusResolved;
}

.reject {
  font-weight: 500;
  color: $powerPoint;
}

.nonAccept {
  font-weight: 500;
  color: $statusProgress;
}

