@import '../../scss/variables/colors.module.scss';

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .mainSpace {
    height: calc(100vh - 95px);
    border-radius: 4px;
    margin: 15px;
    background-color: white;
    border: 1px solid $borderColor;
    border-radius: 4px;
    padding: 15px;
    padding-top: 0;
  }

  .container {
    max-height: calc(100vh - 165px);
    max-width: 100%;
    overflow: auto;

    div[class='ant-collapse ant-collapse-icon-position-start css-dev-only-do-not-override-1pj3upy'] {
      border-bottom: 1px solid $borderColor;
    }

    span {
      ul li {
        list-style-type: inherit !important;
      }

      ol li {
        list-style-type: inherit !important;
      }
    }
  }
}

.section {
  margin-bottom: 20px;
}
