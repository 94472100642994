@import '../../../../scss/variables/colors.module.scss';

.personal_info {
  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-form-item .ant-input-affix-wrapper,
  .ant-form-item input,
  .ant-form-item .ant-select.ant-select-in-form-item .ant-select-selector {
    background-color: rgba(127, 86, 216, 0.05);
  }

  .ant-form-item .ant-input-affix-wrapper input {
    background-color: transparent;
  }

  .ant-form-item .ant-select.ant-select-in-form-item .ant-select-selector {
    border-radius: 4px !important;
  }

  .person_logs {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .logs_key {
      color: #1c2434;
      font-size: 12px;
    }

    .log {
      font-weight: 500;
    }
  }

  .invite_link {
    cursor: not-allowed;
    margin-top: 10px;
    color: $primary100;
  }
}

.ant-timeline-item-label {
  text-align: center !important;
}

.ant-timeline-item div.logs_icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
  background-color: rgba(127, 86, 216, 0.05);
  text-align: center;
  font-size: 15px;
  color: #000;
  border: 2px solid $primary100;
  svg {
    font-size: 20px;
  }
}

.ant-timeline-item-head {
  padding: 0px !important;
  top: 20px !important;
}

.ant-timeline-item-tail {
  border-inline-start: 3px solid $borderColor !important;
}
.ant-collapse-content {
  border: 0px !important;
}
.ant-collapse-item {
  border: 0px !important;
}

.ant-collapse-content-box {
  background-color: white !important;
  padding-top: 5px !important;
  border-radius: 7px;
}

.timeline_spinner {
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 300px;
}

.form_buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
