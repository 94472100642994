@import "../../../scss/variables/colors.module.scss";

// OrganizationClients

.tabsWrap {
  margin-top: 15px;
  border: 1px solid $borderColor;
  border-radius: 4px;
  background-color: white;
  height: calc(100vh - 198px);
  overflow: hidden;
}

.tabs {
  background-color: white;

  div[class*=ant-tabs-tab-btn] {
    color: $textcolor;
    font-weight: 500;
  }

  div[class*=ant-tabs-nav] {
    margin: 4px 2px 0 2px !important;
    border-color: $borderColor;
  };
  
  div[class*=ant-tabs-tab-active] {
    border-color: $primary100 !important;
    border-bottom: 1px solid transparent !important;
  };
}

// Requests



// Schedule



// Subscriptions

.loaderWrap {
  width: 100%;
}

.scheduleWrap {
  padding: 15px;
  width: 100%;
  height: calc(100vh - 280px);
  // background-color: $verylightgrey;
  position: relative;
}

div[class=ant-modal-footer] {
  padding: 0 !important;
}

.scheduleTable {
  height: 100%;
  // padding: 10px;
  // padding: 10px 0 10px 10px;
  border-radius: 5px;
  // border: 1px solid $borderColor;
  background-color: white;
}

.scheduleTitle {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  position: absolute;
  top: 24px;
  background-color: #ffffff;
}

.export{
  color: $textcolor;
  position: absolute;
  right: 15px;
  top: -6px;
  transform: translateY(calc(-150% + 12px));
}

.add{
  color: #ffffff;
  position: absolute;
  right: 162px;
  top: -6px;
  transform: translateY(calc(-150% + 12px));
}

.userCount {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 31px;
  padding: 8px 8px 0 8px;
  font-weight: 500;
  border-radius: 4px;
}

.addModal {
  padding: 32px 16px 16px 16px;
}

.center{
  text-align: center;
}

.waiting{
  background-color: transparent;
}

.inProgress{
  background-color: #fff1b8;
}

.processed{
  background-color: #d9f7be;
}

.select{
  width: 132px;
}
