.modal {
  div[class='ant-modal-body'] {
    padding: 20px;
  }
}

.add {
  display: flex;
  width: 600px;
  flex-wrap: wrap;
  justify-content: space-between;

  &_col {
    width: 50%;
  }

  div[class='ant-space-item'] {
    width: 50%;
  }
}

.addButton {
  background-color: #f5f5f5;
  height: 40px;
  width: fit-content;
  position: sticky;
  bottom: 10px;
  left: 0;
  font-weight: 700;

  &_icon {
    border-radius: 6px;
    color: #fff;
    padding: 1px 5px;
    //   transform: translateY(-1px);
    background-color: #000;
    margin-right: 10px;
  }
}

.fields{
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-bottom: 20px;

  div{
    width: 100%;
    margin-bottom: 0;
  }

  &_deleteIcon{
    font-size: 20px;
  }
}
