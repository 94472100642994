@import '../../scss/variables/colors.module.scss';

.wrapper {
  background-color: $verylightgrey;
  width: 100%;
  height: calc(100vh - 65px);
  padding: 15px;
  overflow: hidden;
}

.organization {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.orgWrap {
  height: calc(100vh - 65px);
}
