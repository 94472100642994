@import "../../../scss/variables/colors.module.scss";

.modal {
  

  .textarea{
    border-radius: 3px;
  }

  .link {
    color: $primary100 !important;
    &:hover {
      opacity: 0.7;
    }
  }

}
