@import '../../../scss/variables/colors.module.scss';

.avatar {
  background-color: #5e5e5e;
}

.modal {
  width: 60vw !important;

  &_actions{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 20px;
  }

  &_body {
    padding: 20px;
  }
}

.list {
  max-height: 50vh;
  overflow: auto;
  margin-top: 20px;

  &_checkGroup{
    user-select: none;
  }

  &_checkbox{
    display: flex;

    .list_item{
      margin-left: 10px;
    }

    span:not([class]){
      display: block;
      width: 100%;
    }
  }

  &_item {
    cursor: pointer;
    transition: 0.3s all linear;
    &:hover {
      background-color: $hover100;
    }
  }

  &_meta {
    align-items: center !important;
  }
}

.title {
  &_role {
    color: #8e8e90;
    margin-left: 10px;
  }
}
