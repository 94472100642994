@import '../../../scss/variables/colors.module.scss';

.modals {
  width: 50vw !important;
}

.modals div[class='ant-modal-body'] {
  padding: 20px;
}

.slickHide {
  opacity: 0;
  margin: 0px;
  padding: 0px;
  height: 0px;
  border: 0px;
  visibility: hidden;
  transition: all 400ms linear;
}

.tree {
  height: 350px;
  padding: 20px;
  border: 1px solid $borderColor;
  border-radius: 4px;
  margin: 20px 0;
  overflow: auto;
  max-width: 840px;

  span[class='ant-tree-iconEle ant-tree-icon__customize'],
  div [class='ant-tree-iconEle ant-tree-icon__customize'] {
    width: fit-content !important;
  }

  &_icon {
    margin-right: 5px;
  }
}

.table{
  margin: 20px 0px;
}

.margin{
  margin: 20px;
}

.pointer{
  cursor: pointer;
}
