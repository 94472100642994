@import '../../../scss/variables/colors.module.scss';

.modalUpload {
  position: relative;

  div[class*=ant-modal-footer] {
    display: none;
  };
}

.uploadMethod {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .titleMethod {
    padding-bottom: 20px;
    font-weight: 600;
    color: $textcolor;
    font-size: 16px;
  }

  .buttonsMethod {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
}

.uploadWrap {
  height: 80vh;
  display: flex;
  overflow-x: hidden;
  width: 100%;

  .menu {
    width: 400px;
    height: 100%;
    background-color: $verylightgrey;
    border-radius: 8px !important;
  }

  ::-webkit-scrollbar-corner {
    color: $verylightgrey;
  }
  ::-webkit-scrollbar-track {
    background-color: $verylightgrey;
  }
  ::-webkit-scrollbar-thumb {
    border-color: $verylightgrey;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }

  .upload {
    border-right: 1px solid $borderColor;
    padding: 20px 18px 20px 18px;
    height: calc(100% - 52px);

    .uploadButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: 1px solid $borderColor;
      height: 150px;
      width: 360px;
      background-color: white;

      &:hover {
        background-color: $hover100;
        cursor: pointer;

        .uploadIcon {
          color: $primary100 !important;
        }
        .uploadButtonText {
          color: $primary100 !important;
        }
      }
    }
  }

  .uploadButtonText {
    text-align: center;
    padding-top: 10px;
    font-size: 16px;
    color: $textcolor;
  }

  .uploadIcon {
    font-size: 48px;
    color: $textcolor;
  }

  .uploadFooter {
    border-right: 1px solid $borderColor;
    border-top: 1px solid $borderColor;
    height: 52px;
    background-color: $hovergrey;
    border-bottom-left-radius: 8px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 15px;
  }
}

.itemsWrap {
  width: calc(100% - 400px);
  padding: 5px 10px;
  overflow-y: scroll;
  height: 100%;
  
  .oneItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    cursor: default;
    margin: 8px 0;
    padding: 8px;
    border: 1px solid $borderColor;
    border-radius: 6px;
  
    .oneItemDiv {
      width: calc(100% - 20px);
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
  
      .oneItemIcon {
        font-size: 28px;
        padding-right: 4px;
      }
  
      .oneItemText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  
    .oneItemDelete {
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
}

.uploadDraggerArea {
  height: 100%;
  width: 100%;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  .dragger {
    height: 90%;
    width: 90%;
    overflow-x: hidden;

    .uploadFiles {
      height: 100%;
      overflow-y: scroll !important;
      padding: 0 10px;

      .uploadScreen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 50%;
        width: auto;
        padding: 20px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    div[class='ant-upload-drag-container'] {
      height: 90%;
      width: 90%;
      overflow-x: hidden;
    }
  }
}
