@import '../../../scss/variables/colors.module.scss';

.deleteWrap {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
  }

  .delete {
    color: $pdf;
  }
}

.mainWrap {
  position: relative;
  padding: 15px;
  background-color: white;
  margin-top: 15px;
  border: 1px solid $borderColor;
  border-radius: 4px;

  .tableStyles {
    width: auto;
    width: calc(100% - 1.5px);
    height: calc(100vh - 279px);
    border-radius: 5px;
  }
}

.addButton {
  margin-top: 15px;
}

.loaderWrap {
  margin-top: 15px;
  border: 1px solid $borderColor;
  border-radius: 4px;
  padding: 0 5px;
  background-color: white;
  height: calc(100vh - 198px);
}

.emptyText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 367px);
}
