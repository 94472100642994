@import '../../scss/variables/colors.module.scss';

.tableWrapper {
  padding: 15px;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 133px);

  .subWrapper {
    border-radius: 4px;
    border: 1px solid $borderColor;
  }
}
