@import '../../../scss/variables/colors.module.scss';

.configureModal {
  width: 96vw !important;
  
  div[class=ant-modal-body]{
    overflow: hidden;
    max-height: 80vh;
    height: 80vh;

    @media (max-width: 501px) {
      overflow-y: scroll;
    }
  }
  

  .displayNone {
    display: none;
  }
  
  .configureWrap {
    display: flex !important;

    @media (min-width: 500px) and (max-width: 10000px) {
      flex-direction: row;
    }
    @media (max-width: 501px) {
      flex-direction: column;
    }
  }

  .groupsSection {
    padding: 20px 0 20px 10px;
    height: 80vh;
    border-right: 1px solid $borderColor;
    overflow-y: scroll;

    @media (min-width: 500px) and (max-width: 10000px) {
      width: 300px;
    }
    @media (max-width: 501px) {
      width: 100%;
    }

    span[class*=ant-tree-switcher]{
      padding: 5px 0 !important;
    }

    span[class*=ant-tree-node-selected]{
      font-weight: 600;
    }

    span[class*=ant-tree-node-content-wrapper]{
      padding: 6px !important;
      width: 240px;
    }

    span[class*=ant-tree-indent]{
      display: none;
    }
    
    .addRole {
      height: 35px;
      width: 240px;
      margin: 1px 0 0 24px
    }
  }

  .configureSection {
    height: 80vh;

    @media (min-width: 500px) and (max-width: 10000px) {
      width: calc(100% - 300px);
    }

    @media (max-width: 501px) {
      border-top: 1px solid $borderColor;
      width: 100%;
    }

    .inputsWrap {
      padding: 30px 30px 10px 30px;
      border-bottom: 1px solid $borderColor;

      .input {
        height: 56px;
      }
    }

    .permissionsWrap {
      // position: relative;
      overflow-y: scroll;
      padding: 0px 5px 10px 20px;
      height: calc(100% - 93px);
      
      .configureTitle {
        // position: sticky;
        // top: 0;
        // background-color: white;
        // z-index: 1110;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        flex-direction: row;
        font-weight: 600;
        font-size: 16px;
        padding: 10px 10px 0 0;


        .resetButton {
          font-weight: 500;
          color: $primary100;
          padding: 0px;

          &:hover {
            opacity: .65;
          }
        }

        .hideButton {
          display: none;
        }
      }

      .permissionItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 100px;

        &:not(:last-child) {
          border-bottom: 1px solid $borderColor;
        }

        .permissionTitle {
          font-size: 14px;
          font-weight: 600;
          color: $textcolor;
        }
        
        .permissionDescription {
          padding-top: 15px;
          font-size: 12px;
          color: $textcolor;
        }
      }

      .noFound {
        display: flex;
        width: 100%;

        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: $textcolor;
        padding: 40px;
      }
    }

    .chooseSomeGroup {
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      div {
        padding: 0 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 100%;
        border-radius: 15px;
        border: 1px solid $borderColor;
        font-weight: 600;
        font-size: 28px;
        color: $borderColor;
      }
    }
  }
};

.titleGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .deleteIcon {
    color: $textcolor;

    &:hover {
      opacity: .8;
      color: red;
    }
  }
}

.search{
  padding: 10px;
}
