.loading {
  position: fixed;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: .2;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
